import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import HomePage from '../components/pages/HomePage';
import InvitationCard from '../components/pages/InvitationCard';
import Account from '../components/accounts/Account'
import Dashboard from '../components/dashboard/Dashboard';
import Login from '../login/Login';
import MyCards from '../components/dashboard/MyCards';
import Invoices from '../components/dashboard/Invoices';
import FaimlyAndFriends from '../components/dashboard/FaimlyAndFriends';
import Feedback from '../components/dashboard/Feedback';
import Form from '../components/Form'
import DragAndDrop from '../components/DragAndDrop';
import FAQ from '../components/component/FAQ';
import CardLayout from '../view/cards/CardLayout';
import InvitationCardMaster from '../view/cards/InvitationCardMaster';

import ComingSoon from '../view/coming_soon/ComingSoon';
import PrivacyPolicy from '../view/common/PrivacyPolicy';
import RefundPolicy from '../view/common/RefundPolicy';
import TermsAndConditions from '../view/common/TermsAndConditions';
import DeleteAccountAndPolicy from '../view/common/DeleteAccountAndPolicy';
import SupportTicket from '../view/common/SupportTicket';
import DeleteAccountPage from '../view/common/DeleteAccountPage'
import ContentTakedownRequestPage from '../view/common/ContentTakedownRequestPage';
import ProtectedRoutes from './protectedRoutes';
import CompleteProfile from '../components/dashboard/CompleteProfile';
import PaymentSuccesssScreen from '../view/payment_screen/PaymentSuccesssScreen';
import PaymentWaitingScreen from '../view/payment_screen/PaymentWaitingScreen';
import PaymentFailed from '../view/payment_screen/PaymentFailed';
import AndroidAppPage from '../view/coming_soon/AndroidAppPage';
import IosAppPage from '../view/coming_soon/IosAppPage';
import CardSubmittedSuccessfullyScreen from '../view/cards/CardSubmittedSuccessfully';
import AboutUsPage from '../view/support_pages/AboutUsPage';
import ContactUsPage from '../view/support_pages/ContactUsPage';
import MyProfile from '../components/dashboard/MyProfile';
import CreateProfile from '../components/dashboard/CreateProfile';
import EditProfileDetails from '../components/dashboard/EditProfileDetails';
import DiwaliOffer from '../components/pages/DiwaliOffer';

// TODO: path=   cards/engagement/engagement-Card-Id
const RouterSetup = () => {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/coming-soon' element={<ComingSoon />} />
          <Route path='/android-app' element={<AndroidAppPage />} />
          <Route path='/ios-app' element={<IosAppPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/delete-account-policy' element={<DeleteAccountAndPolicy />} />
          <Route path='/support-ticket' element={<SupportTicket />} />
          <Route path='/delete-account-request' element={<DeleteAccountPage />} />
          <Route path='/content-takedown-request' element={<ContentTakedownRequestPage />} />
          <Route path='/payment-success-screen' element={<PaymentSuccesssScreen />} />
          <Route path='/payment-waiting-screen' element={<PaymentWaitingScreen />} />
          <Route path='/payment-failed-screen' element={<PaymentFailed />} />
          <Route path='/card-submitted-successfully' element={<CardSubmittedSuccessfullyScreen />} />


          <Route path="cards" >
            <Route path='' element={<CardLayout />}>
              {/* Wedding category routes */}
              <Route path=':group_path' element={<InvitationCardMaster />} />
            </Route>
            <Route path=':group_path/:card_id' element={<InvitationCard />} />
          </Route>

          <Route path="account" element={<Account />} />
          <Route path="drag-and-drop" element={<DragAndDrop />} />

          <Route path="login" element={<Login />} />

          <Route path="" element={<ProtectedRoutes />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<CompleteProfile />} />
              <Route path="complete-profile" element={<CompleteProfile />} />
              <Route path="my-cards" element={<MyCards />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="family-and-friends" element={<FaimlyAndFriends />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="my-profile" element={<MyProfile />} />
              <Route path="create/:profile" element={<CreateProfile />} />
              <Route path="edit/:profileId" element={<EditProfileDetails />} />
            </Route>

          </Route>
          <Route path="form" element={<Form />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />


          <Route path="diwali-offer" element={<DiwaliOffer />} />


        </Route>
      </Routes>
    </Router>
  )
}

export default RouterSetup;