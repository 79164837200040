import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, FormControlLabel, Checkbox, Radio, RadioGroup, Button, Card, CardMedia, Link, CardContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Rating from '@mui/material/Rating';
import canva from '../../assets/icons/canva.webp'
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { cardPaymentSuccess, createCardForCustomerData, getSignedUrlDirectly, getSingleCardDetails, getSingleCardDetailsDirectly } from '../../redux/actions/cardsForCustomerActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { getAllCardSuggestionData } from '../../redux/actions/getAllCardSuggestionActions';
import axios from 'axios';

function DiwaliOffer() {


    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        "Haldi Event Date": '',
        "Haldi Event Time": '',
        "Mehndi Event Date": '',
        "Mehndi Event Time": '',
        "Sangeet Event Date": '',
        "Sangeet Event Time": '',
        "Wedding Event Date": '',
        "Wedding Event Time": '',
        "Bride Name": '',
        "Bride Parent Name": '',
        "Bride Address": '',
        "Groom Name": '',
        "Groom Parent Name": '',
        "Groom Address": '',
        "Family Members": '',
        "Card For": 'bride',
        "Customer Name": '',
        "Customer Mobile": '',
        "Customer Email": ''
    });

    const [timeLeft, setTimeLeft] = useState('');
    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();
            const currentSecond = now.getSeconds();

            // Calculate the end of the current slot
            const slotEndHour = Math.ceil((currentHour + 1) / 6) * 6;
            const slotEnd = new Date(now);
            slotEnd.setHours(slotEndHour, 0, 0, 0);

            // Calculate the remaining time
            const timeDiff = slotEnd - now;
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            setTimeLeft(`${String(hours).padStart(2, '0')} hr ${String(minutes).padStart(2, '0')} min ${String(seconds).padStart(2, '0')} sec`);
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const fetchCardDetails = async () => {
            // setLoading(true);
            // // let cardData = await getSingleCardDetailsDirectly(params.card_id, params.group_path, 'pdf');
            // // if (cardData) {
            // //     setCardDetails(cardData.data);
            // // }
            // // console.log("cardDetails", cardDetails);
            // setLoading(false);
        };

        fetchCardDetails();
    }, [dispatch]);


    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };




    const handleFieldChange = (field, event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isFormValid = () => {
        return formData["Haldi Event Date"] && formData["Haldi Event Time"] && formData["Mehndi Event Date"] && formData["Mehndi Event Time"] && formData["Sangeet Event Date"] && formData["Sangeet Event Time"] && formData["Wedding Event Date"] && formData["Wedding Event Time"] && formData["Bride Name"] && formData["Bride Parent Name"] && formData["Bride Address"] && formData["Groom Name"] && formData["Groom Parent Name"] && formData["Groom Address"] && formData["Family Members"];
    };


    const handleFieldChange2 = (field, event) => {
        let newValue = event.target.value;

        // Handle field validation
        let validation = field.field_validation;

        if (field.field_type === 'file') {
            // handleFileUpload(field, event);
        }

        if (field.field_type === 'date') {
            // date example - 25 january 2025
            const dateValue = new Date(newValue);
            if (!isNaN(dateValue)) {
                field['field_value'] = formatDate(dateValue);
            } else {
                console.error("Invalid date format");
            }
        }
        if (field.field_type === 'time') {
            const timeParts = newValue.split(':');
            let hours = parseInt(timeParts[0], 10);
            const minutes = timeParts[1];
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            field['field_value'] = `${hours}:${minutes} ${ampm}`;
            // field['field_value'] = newValue;
        }

        if (field.field_type === 'time') {
            field['field_value'] = newValue;
        }

        if (validation === 'STRING_80') {
            if (newValue.length <= 80) {
                field['field_value'] = newValue;
            } else {
                console.error("The value should not be greater than 80 characters");
            }
        } else if (validation === 'EMAIL') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(newValue)) {
                field['field_value'] = newValue;
            } else {
                console.error("Invalid email format");
            }
        } else if (validation === 'NUMBER') {
            if (!isNaN(newValue)) {
                field['field_value'] = newValue;
            } else {
                console.error("Number is not valid");
            }
        } else if (validation === 'DATE_FORWARD') {
            const inputDate = new Date(newValue);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (inputDate <= today) {
                field['field_value'] = newValue;
            } else {
                console.error('The date cannot be later than today');
            }
        }

        console.log("Updated Field:", field);
    };



    // function loadScript(src) {
    //     return new Promise((resolve) => {
    //         const script = document.createElement("script");
    //         script.src = src;
    //         script.onload = () => {
    //             resolve(true);
    //         };
    //         script.onerror = () => {
    //             resolve(false);
    //         };
    //         document.body.appendChild(script);
    //     });
    // }

    // async function initiateRazorPayPayment(amount, currency, order_name, order_description, rzpOrderId, rzpKey, customer_name, customer_email, customer_mobile, entry_id) {
    //     const res = await loadScript(
    //         "https://checkout.razorpay.com/v1/checkout.js"
    //     );

    //     if (!res) {
    //         alert("Razorpay SDK failed to load. Are you online?");
    //         return;
    //     }


    //     const options = {
    //         key: rzpKey, // Enter the Key ID generated from the Dashboard
    //         amount: amount.toString(),
    //         currency: currency,
    //         name: order_name,
    //         description: order_description,
    //         // image: { logo },
    //         order_id: rzpOrderId,
    //         handler: async function (response) {
    //             console.log("Razorpay Response", response);
    //             const data = {
    //                 entry_id: entry_id,
    //                 orderCreationId: rzpOrderId,
    //                 razorpayPaymentId: response.razorpay_payment_id,
    //                 razorpayOrderId: response.razorpay_order_id,
    //                 razorpaySignature: response.razorpay_signature,
    //             };

    //             let paymentReconcileData = await cardPaymentSuccess(entry_id, data);


    //             console.log("Payment Success", data);
    //             if (paymentReconcileData && paymentReconcileData.data) {
    //                 switch (paymentReconcileData.data.action) {
    //                     case 'payment_success_screen':
    //                         navigate(`/payment-success-screen`);
    //                         break;
    //                     case 'payment_waiting_screen':
    //                         /** Navigate to payment waiting screen*/
    //                         navigate(`/payment-waiting-screen`);
    //                         break;
    //                     case 'payment_failed_screen':
    //                         /**navigate to payment failed screen */
    //                         navigate(`/payment-failed-screen`);
    //                         break;
    //                     default:
    //                         /** Navigate to login */
    //                         navigate(`/card-submitted-successfully`);
    //                         break;
    //                 }
    //             }
    //         },
    //         prefill: {
    //             name: customer_name,
    //             email: customer_email,
    //             contact: customer_mobile,
    //         },
    //         // notes: {
    //         //   address: "Soumya Dey Corporate Office",
    //         // },
    //         theme: {
    //             color: "#61dafb",
    //         },
    //         "modal": {
    //             "ondismiss": function () {
    //                 console.log('Checkout form closed');
    //                 navigate(`/payment-failed-screen`);
    //             }
    //         }
    //     };

    //     const paymentObject = new window.Razorpay(options);
    //     paymentObject.open();
    // }

    const handleSubmit = async () => {
        // let body = {
        //     card_name: cardDetails.card_name,
        //     card_description: cardDetails.card_description,
        //     card_fields: cardDetails.card_fields.map(({ field_name, id, field_value }) => ({
        //         field_name,
        //         id,
        //         field_value: field_value || ""
        //     })),
        //     customer_name: customerName,
        //     customer_mobile: customerMobile,
        //     customer_email: customerEmail
        // };
        // console.log("Body", body);

        // let rsp = await createCardForCustomerData(params.card_id, body);
        // console.log("RSP", rsp);
        // //   {
        // //     "status": "success",
        // //     "message": "Card created successfully",
        // //     "data": {
        // //         "action": "payment_screen",
        // //         "attempt_id": "5ad5a8e8-86b6-4d7d-9284-90d58761f68d",
        // //         "orderId": "order_P4mNhD8TZrQZnl",
        // //         "amount": 100,
        // //         "currency": "INR",
        // //         "receipt": "5ad5a8e8-86b6-4d7d-9284-90d58761f68d",
        // //         "status": "created",
        // //         "attempts": 0,
        // //         "created_at": 1728005307,
        // //         "offer_id": null,
        // //         "key": "rzp_live_L3aKyZWl8SRyYR"
        // //     }
        // // }
        // // await dispatch(fetchAllDiwaliOffers(cardId));

        // if (rsp && rsp.data) {
        //     switch (rsp.data.action) {
        //         case 'payment_screen':
        //             /** Navigate to payment screen */
        //             await initiateRazorPayPayment(
        //                 rsp.data.amount,
        //                 rsp.data.currency,
        //                 cardDetails.card_name,
        //                 cardDetails.card_description,
        //                 rsp.data.orderId,
        //                 rsp.data.key,
        //                 customerName,
        //                 customerEmail,
        //                 customerMobile,
        //                 rsp.data.attempt_id
        //             );

        //             break;
        //         case 'login_screen':
        //             /** Navigate to Login Screen */
        //             navigate(`/card-submitted-successfully`);
        //             break;
        //         default:
        //             /** Navigate to login */
        //             navigate(`/card-submitted-successfully`);
        //             break;
        //     }
        // }
    };

    return (
        <Box sx={{ padding: 2, marginX: 'auto', maxWidth: '1200px', marginTop: 5, overflowX: 'hidden' }}>


            <>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    Diwali Combo Offer
                </Typography>
                <Typography sx={{ fontSize: '1.8rem', textAlign: 'left', fontFamily: 'inherit' }}>
                    You will get Total 16 Invitation Card and Video Only at Rs. 399 /-
                </Typography>


                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Haldi PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Mehndi PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Sangeet PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Shadi PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Haldi Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Mehndi Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Sangeet Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Shadi Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '1rem', fontWeight: 450, fontFamily: 'inherit', color: 'red' }}>
                    Limited Slots Available. FIRST come FIRST get. Price Increasing soon. Hurry Up!
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                    Time left in current slot: <Box component="span" sx={{ color: 'red', fontWeight: '600' }}>{timeLeft}</Box>
                </Typography>

                {/* <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2 }}>
                    <Rating value={5} readOnly />
                </Box> */}

                <Typography sx={{ textAlign: 'left', fontSize: '1rem', fontWeight: 450, fontFamily: 'inherit', color: 'black' }}>
                    Fill the details below to get the card and video
                </Typography>

                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#F0F4FA' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Making Card For
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl fullWidth required={true}>
                            <InputLabel id="500"></InputLabel>
                            <Select
                                labelId="500"
                                name="Card For"
                                id="500"
                                defaultValue="bride"
                                onChange={event => handleFieldChange({}, event)}
                            >
                                <MenuItem key="1" value="bride">
                                    Bride/Girl
                                </MenuItem>
                                <MenuItem key="2" value="groom">
                                    Groom/Boy
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#E2EAF4' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Date and Time
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Haldi Event Date"
                                    id="1"
                                    label="Haldi Event Date"
                                    placeholder="Haldi Event Date"
                                    type="date"
                                    value={formData["Haldi Event Date"]}
                                    required={true}
                                    onChange={event => handleFieldChange("date", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Haldi Event Time"
                                    id="2"
                                    label="Haldi Event Time"
                                    placeholder="Haldi Event Time"
                                    type="time"
                                    value={formData["Haldi Event Time"]}
                                    required={true}
                                    onChange={event => handleFieldChange("time", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Mehndi Event Date"
                                    id="1"
                                    label="Mehndi Event Date"
                                    placeholder="Mehndi Event Date"
                                    type="date"
                                    value={formData["Mehndi Event Date"]}
                                    required={true}
                                    onChange={event => handleFieldChange("date", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Mehndi Event Time"
                                    id="2"
                                    label="Mehndi Event Time"
                                    placeholder="Mehndi Event Time"
                                    type="time"
                                    value={formData["Mehndi Event Time"]}
                                    required={true}
                                    onChange={event => handleFieldChange("time", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Sangeet Event Date"
                                    id="1"
                                    label="Sangeet Event Date"
                                    placeholder="Sangeet Event Date"
                                    type="date"
                                    value={formData["Sangeet Event Date"]}
                                    required={true}
                                    onChange={event => handleFieldChange("date", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Sangeet Event Time"
                                    id="2"
                                    label="Sangeet Event Time"
                                    placeholder="Sangeet Event Time"
                                    type="time"
                                    value={formData["Sangeet Event Time"]}
                                    required={true}
                                    onChange={event => handleFieldChange("time", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Wedding Event Date"
                                    label="Wedding Event Date"
                                    type="date"
                                    value={formData["Wedding Event Date"]}
                                    required={true}
                                    onChange={event => handleFieldChange("date", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Wedding Event Time"
                                    label="Wedding Event Time"
                                    type="time"
                                    value={formData["Wedding Event Time"]}
                                    required={true}
                                    onChange={event => handleFieldChange("time", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>



                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#E8F0FE' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Bride (Girl) Details - English Only
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name="Bride Name"
                                    id="1"
                                    label="Bride/Girl Name"
                                    placeholder="Bride/Girl Name"
                                    type="string"
                                    value={formData["Bride Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Bride Parent Name"
                                    id="2"
                                    label="Bride/Girl Parent Name"
                                    placeholder="Bride/Girl Parent Name"
                                    type="string"
                                    value={formData["Bride Parent Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Bride Address"
                                    id="2"
                                    label="Bride/Girl Address"
                                    placeholder="Bride/Girl Address"
                                    type="string"
                                    value={formData["Bride Address"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>



                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#EDF2F7' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Groom (Boy) Details - English Only
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name="Groom Name"
                                    id="1"
                                    label="Groom/Boy Name"
                                    placeholder="Groom/Boy Name"
                                    type="string"
                                    value={formData["Groom Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Groom Parent Name"
                                    id="2"
                                    label="Groom/Boy Parent Name"
                                    placeholder="Groom/Boy Parent Name"
                                    type="string"
                                    value={formData["Groom Parent Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Groom Address"
                                    id="2"
                                    label="Groom/Boy Address"
                                    placeholder="Groom/Boy Address"
                                    type="string"
                                    value={formData["Groom Address"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>



                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#F5F7FA' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Your Family Details - English Only
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name="Family Members"
                                    id="1"
                                    label="Your Family Members"
                                    placeholder="Your Family Members"
                                    type="string"
                                    value={formData["Family Members"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_250", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 220 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>

                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                    Time left in current slot: <Box component="span" sx={{ color: 'green', fontWeight: '600' }}>{timeLeft}</Box>
                </Typography>


                <Button
                    variant="contained"
                    color="error"
                    sx={{ marginTop: 2, width: '100%' }}
                    onClick={() => handleSubmit()}
                    disabled={!isFormValid()}
                >
                    Submit Details
                </Button>

                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            You can not modify the details once submitted. Please make sure all the details are correct.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Upto 4hrs will be taken to create the card and video. You will get the download link on your email or whatsapp or you can download from the website by login with the above mobile number.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Our designs are fixed and you can not change the design once submitted. If you want to change the design, you need to pay again. Branding can not be removed. Please see sample above.
                        </Typography>
                    </Box>
                </Grid>
            </>

        </Box >
    );
}

export default DiwaliOffer;
